import React from 'react';
import styled from 'styled-components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Title = styled.h1`
    font-ze: 2.5em;
`;

const NewsDescription = styled.p`
    font-size: .8em;
`;
const NewsEntry = ({ createdAt, description, title }) => {
    const day = new Date(createdAt).getDate() + 2;
    const month = new Date(createdAt).getMonth() + 1;
    const year = new Date(createdAt).getUTCFullYear();

    return (
        <div>
            <Title>{`${day}/${month}/${year}`}</Title>
            <NewsDescription>{documentToReactComponents(JSON.parse(description.raw))}</NewsDescription>
        </div>
    );
};

export default NewsEntry;