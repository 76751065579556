import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Header } from '../Header';
import NewsEntry from './NewsEntry';
import { BackButton } from "../Button/Button";

const Container = styled.div`
    font-family: 'Roboto', sans-serif;
`
const NewsContainer = styled.div`
    padding: 0 4em;
    display: flex;
    flex-direction: column;
    line-height: 1.6;
    margin-top: 2.5em;
    font-size: 1.1em;

    @media screen and (min-width: 320px) and (max-width: 1024px) {
        margin-top: 1.5em;
        padding: 0 2em    
    }
`;

const LinesImage = styled.img`
  height: 13em;

  @media screen and (min-width: 320px) and (max-width: 1024px) {
    height: 6em;   
    }
`;

const NewsSectionsContainerWithLines = styled.div`
  display: flex;
  flex-direction: row;
`;

const NoNewsToShowTitle = styled.h1`
    color: #545556;
    text-align: center;
    font-size: 2.5em;
    @media screen and (min-width: 320px) and (max-width: 1024px) {
        font-size: 1.5em;
        text-align: left;    
    }
`;

const mapStateToProps = ({ language }) => ({ lang: language.lang });

const NewsPageContent = (props) => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulNewsPageContent {
                nodes {
                    news
                    leftLinesImage {
                        file {
                          url
                        }
                    }
                }
            }
        }
    `);

    const pageContent = data.allContentfulNewsPageContent.nodes[0];
    const isEnglish = props.lang === '' || props.lang === 'En-gb';
    return (
        <Container>
            <BackButton text="Back" />
            <Helmet>
                <link href="https://fonts.googleapis.com/css?family=Roboto" rel="stylesheet" />
            </Helmet>
            <Header>{isEnglish ? 'NEWS' : 'NEA'}</Header>
            <NewsSectionsContainerWithLines>
                <LinesImage src={pageContent.leftLinesImage.file.url} />
                <NewsContainer>
                    {(!pageContent.newsEntries || !pageContent.newsEntries.length) ? <NoNewsToShowTitle>{isEnglish ? 'No news to show' : 'Δεν υπάρχουν νέα αυτή την στιγμή'}</NoNewsToShowTitle> : pageContent.newsEntries.map(entry => <NewsEntry {...entry} />)}
                </NewsContainer>
            </NewsSectionsContainerWithLines>
        </Container>
    )
}

export default connect(mapStateToProps)(NewsPageContent);